<template>
    <form autocomplete="off">
        <is-loading v-if="isLoading" :box="true" />
        <div class="row g-3">
            <div class="col-12">
                <label>Case duties
                    (<a @click.prevent="data.items = caseDuties.map(i => i.id)" href="#">Click to add all</a>)</label>
                <multiselect 
                mode="tags"
                :close-on-select="false"
                searchable
                valueProp="id"
                trackBy="title" label="title"
                :options="caseDuties" 
                v-model="data.items" placeholder="--select--"></multiselect>
            </div> 

            <div class="col-12">
                <div class="d-flex mt-4 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="save()"
                     type="button"> Submit </b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue';
import Multiselect from '@vueform/multiselect'

export default {
    components:{
        Multiselect,
        IsLoading
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
    },
    data(){
        return{
            isLoading: false,
            data: {
                items: [],
            },
        }
    },
    computed:{
        caseDuties(){
            return this.$store.state.duty.list
        },
    },
    methods:{
        save(){
            const formData = new FormData()
            formData.append('sync_item', 'case_duties')
            formData.append('data', JSON.stringify(this.data))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/departments/${this.editItem.id}/sycn-relations`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$emit("updateItem", response.data.data)
                        this.resetForm()
                    }
                })
        },
        resetForm(){
            this.$emit("closeMe")
        },
        getOptionData(){
            this.isLoading = true;
            this.$store.dispatch('duty/fetchAllItems')
            .then(response => this.isLoading = false );
        },
    },
    mounted(){
        this.getOptionData()
        this.data.items = this.editItem.case_duties.map(i => i.id);
    }
}
</script>