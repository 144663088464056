
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="department" class="col-12">
      <h5 class="mb-3">Department Details</h5>
      <div class="row">
        <div class="col-md-4 order-md-2">
          <div class="card overflow-hidden position-sticky" style="top: 90px;">
            <div class="bg-primary py-4">
            </div>
            <div v-if="department.head" class="card-body text-center">
              <router-link :to="`/users/${department.head.id}`" class="avatar rounded-circle avatar-lg card-avatar card-avatar-top">
                <img :src="absoluteUrl(department.head.image)" class="avatar-img rounded-circle border border-4 border-card" alt="head of department">
              </router-link>
              <div>
                <p class="small text-muted mb-3">
                  Head of Department
                </p>
                <h3 class="card-title">
                  <router-link :to="`/users/${department.head.id}`">{{department.head.first_name}} {{department.head.last_name}}</router-link>
                </h3>
                <p class="mb-0">{{department.head.mobile}}</p>
                <p class="mb-0">{{department.head.email}}</p>
              </div>
            </div>
            <div v-else class="card-body text-center">
              There is no Director assigned to this department
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card mb-4 overflow-hidden">
            <div class="card-body">
              <div class="row">
                <div class="col-10 col-sm-8">
                  <div class="d-flex align-items-center justify-content-start mb-3">
                    <div class="">
                      <h4 class="lh-base mb-1">{{department.title}}</h4>
                    </div>
                  </div>
                </div>
                <div class="col-2 col-sm-4 text-end">
                  <div class="dropdown dropstart no-arrow">
                    <button class="dropdown-toggle btn btn-sm btn-light" type="button"
                     role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="ri-more-2-fill"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <button @click="popupModalShow = true" class="dropdown-item"
                        type="button" >
                          Update department </button>
                      </li>
                      <li>
                        <button @click.prevent="deleteItem()"
                        class="dropdown-item" type="button">
                          Delete department </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row g-3">
                    <div class="col-12">
                        <label class="form-label fw-bold">About department</label>
                        <div>{{department.description}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="py-5">
            <department-lawyers />
          </div>

          <div class="row g-3">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header d-flex align-items-center justify-content-between">
                  <h6 class="card-header-title m-0">Case categories</h6>
                  <button @click="popupModalCaseCategories = true" type="button" class="btn btn-sm btn-primary">Manage categories</button>
                </div>
                <div class="card-body">
                  <ol class="ps-3">
                    <li v-for="(case_category, caIndex) in department.case_categories" :key="caIndex">
                      <h6>{{case_category.title}}</h6>
                      <p><small>{{case_category.description}}</small></p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card">
                <div class="card-header d-flex align-items-center justify-content-between">
                  <h6 class="card-header-title m-0">Case Stages</h6>
                  <button @click="popupModalStages = true" type="button" class="btn btn-sm btn-primary">Manage stages</button>
                </div>
                <div class="card-body">
                  <ol class="ps-3">
                    <li v-for="(case_stage, csIndex) in department.case_stages" :key="csIndex">
                      <h6>{{case_stage.title}}</h6>
                      <p><small>{{case_stage.description}}</small></p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card">
                <div class="card-header d-flex align-items-center justify-content-between">
                  <h6 class="card-header-title m-0">Case Duties</h6>
                  <button @click="popupModalDuties = true" type="button" class="btn btn-sm btn-primary">Manage duties</button>
                </div>
                <div class="card-body">
                  <ol class="ps-3">
                    <li v-for="(case_duties, cdIndex) in department.case_duties" :key="cdIndex">
                      <h6>{{case_duties.title}}</h6>
                      <p><small>{{case_duties.description}}</small></p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
  <b-modal v-model="popupModalShow" hide-footer centered
    title="Update department" body-class="loading-viewport"
    title-class="fs-6" @hide="initializeAll()">
    <department-form v-if="popupModalShow" @closeMe="initializeAll()"
    :editItem="department" :editMode="true" @updatedItem="updateItem($event)"/>
  </b-modal>

  <b-modal fullscreen v-model="popupModalCaseCategories" body-class="loading-viewport" hide-footer centered
    title="Manage Case Categories"
    title-class="fs-6" @hide="initializeAll()">
    <department-case-categories-form v-if="popupModalCaseCategories" @closeMe="initializeAll()"
    :editItem="department" @updateItem="updateItem($event)" />
  </b-modal>

  <b-modal fullscreen v-model="popupModalStages" body-class="loading-viewport" hide-footer centered
    title="Manage Case Stages"
    title-class="fs-6" @hide="initializeAll()">
    <department-stages-form v-if="popupModalStages" @closeMe="initializeAll()"
    :editItem="department" @updateItem="updateItem($event)" />
  </b-modal>

  <b-modal fullscreen v-model="popupModalDuties" body-class="loading-viewport" hide-footer centered
    title="Manage Case Duties"
    title-class="fs-6" @hide="initializeAll()">
    <department-duties-form v-if="popupModalDuties" @closeMe="initializeAll()"
    :editItem="department" @updateItem="updateItem($event)" />
  </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import DepartmentForm from '@/components/forms/DepartmentForm.vue';
import DepartmentLawyers from './DepartmentLawyers.vue';
import DepartmentCaseCategoriesForm from '@/components/forms/DepartmentCaseCategoriesForm.vue';
import DepartmentStagesForm from '@/components/forms/DepartmentStagesForm.vue';
import DepartmentDutiesForm from '@/components/forms/DepartmentDutiesForm.vue';

export default {
  name: "department-show",
  components:{
    IsLoading,
    DepartmentForm,
    DepartmentLawyers,
    DepartmentCaseCategoriesForm,
    DepartmentStagesForm,
    DepartmentDutiesForm,
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      popupModalCaseCategories: false,
      popupModalStages: false,
      popupModalDuties: false,
      department: null
    }
  },
  watch: {
    '$route.params.departmentId': function (id){
      if(id) this.fetchItem()
    },
  },
  methods: {
    updateItem(item){
      Object.assign(this.department, item)
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/departments/${this.$route.params.departmentId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.department = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    deleteItem(){
      this.confirmDelete({
        text: "You want to delete a department permanently",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/departments/${this.department.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path: '/departments'})
            }
          })
        }
      });
    },
    initializeAll(){
      this.popupModalShow = false;
      this.popupModalCaseCategories = false;
      this.popupModalStages = false;
      this.popupModalDuties = false;
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

